import React from "react";

import type ProfileDto from "smavesto.core/lib/types/dto/profile/ProfileDto";

import { getComma, mapDBValue } from "../helpers";

type AdditionalIncomeListProps = { list: ProfileDto["additionalIncome"] };

export const AdditionalIncomeList: React.FunctionComponent<
  AdditionalIncomeListProps
> = ({ list }) => (
  <>
    {list.map((v, i, a) => (
      <div key={v}>
        {i + 1}.&nbsp;
        {mapDBValue("profile", "additionalIncome", v)}
        {getComma(i !== a.length - 1)}
      </div>
    ))}
  </>
);
