import type { Rule } from "antd/lib/form";
import { countryValidator, phoneValidator } from "./validators";

export const RADIO_GENDERS = [
  { label: "Herr", value: "MALE" },
  { label: "Frau", value: "FEMALE" }
];

export const RADIO_POLITICALLY = [
  { label: "Ja", value: true },
  { label: "Nein", value: false }
];

export const USER_TITLES = [
  { label: "-", value: "-" },
  { label: "Dr.", value: "Dr." },
  { label: "Prof.", value: "Prof." },
  { label: "Magister", value: "Magister" }
];
export const MARRIED_STATUSES = [
  { label: "Ledig", value: "LEDIG" },
  { label: "Verheiratet", value: "VERHEIRATET" },
  { label: "Eingetragene Lebenspartnerschaft", value: "LEBENSPARTNERSCHAFT" },
  {
    label: "Geschieden",
    value: "GESCHIEDEN"
  },
  {
    label: "Verwitwet",
    value: "VERWITWET"
  }
];

export const JOB_STATUSES_UNDERAGE = [
  { label: "Angestellte/r", value: "ANGESTELLTER" },
  {
    label: "Angestellte/r im öffentl. Dienst",
    value: "ANGESTELLTER_OEFFENTLICHER_DIENST"
  },
  { label: "Arbeitnehmer/in", value: "ARBEITNEHMER" },
  { label: "Arbeitslos", value: "ARBEITSLOS" },
  { label: "Beamtin/Beamter", value: "BEAMTER" },
  { label: "Hausfrau/Hausmann", value: "HAUSFRAU" },
  { label: "Im Ruhestand", value: "IM_RUHESTAND" },
  { label: "Schüler/in, Student/in, Auszubildende/r", value: "SCHUELER" },
  { label: "Selbstständige/r", value: "SELBSTSTAENDIGER" },
  { label: "Sonstiges (z.B. Kind)", value: "SONSTIGES" }
];

export const JOB_STATUSES = [
  { label: "Angestellte/r", value: "ANGESTELLTER" },
  {
    label: "Angestellte/r im öffentl. Dienst",
    value: "ANGESTELLTER_OEFFENTLICHER_DIENST"
  },
  { label: "Arbeitnehmer/in", value: "ARBEITNEHMER" },
  { label: "Arbeitslos", value: "ARBEITSLOS" },
  { label: "Beamtin/Beamter", value: "BEAMTER" },
  { label: "Hausfrau/Hausmann", value: "HAUSFRAU" },
  { label: "Im Ruhestand", value: "IM_RUHESTAND" },
  { label: "Schüler/in, Student/in, Auszubildende/r", value: "SCHUELER" },
  { label: "Selbstständige/r", value: "SELBSTSTAENDIGER" },
  { label: "Sonstiges", value: "SONSTIGES" }
];

export const ADDITIONAL_INCOME = [
  {
    label: "Nichtselbstständige Tätigkeit",
    value: "NICHT_SELBSTAENDIGE_TAETIGKEIT"
  },
  {
    label: "Selbstständige Tätigkeit / Gewerbebetrieb",
    value: "SELBSTAENDIGE_TAETIGKEIT"
  },
  { label: "Vermietung und Verpachtung", value: "VERMIETUNG_VERPACHTUNG" },
  { label: "Kapitalvermögen", value: "KAPITALVERMOEGEN" },
  { label: "Sonstiges", value: "SONSTIGES" }
];

export const INDUSTRIES = [
  { label: "Bergbau und Gewinnung von Steinen und Erden", value: "BERGBAU" },
  { label: "Einzelhandel (ohne Handel mit Kfz)", value: "EINZELHANDEL" },
  { label: "Energieversorgung", value: "ENERGIEVERSORGUNG" },
  {
    label:
      "Erbringung von sonstigen Dienstleistungen – Dienstleistungen des Sports, der Unterhaltung und der Erholung",
    value: "SONSTIGE_SPORT_WELLNESS"
  },
  {
    label:
      "Erbringung von wirtschaftlichen Dienstleistungen für Unternehmen & Privatpersonen",
    value: "WIRTSCHAFT"
  },
  { label: "Erziehung u. Unterricht", value: "ERZIEHUNG" },
  { label: "Gastronomie", value: "GASTRONOMIE" },
  { label: "Gesundheitswesen", value: "GESUNDHEIT" },
  { label: "Grundstücks- u. Wohnungswesen", value: "GRUND_WOHNUNG" },
  {
    label: "Herst. v. Glas und Glaswaren, Verarbeitung von Steinen",
    value: "GLASWAREN"
  },
  {
    label: "Herstellung von Kraftfahrzeugen u. Kraftfahrzeugteilen",
    value: "HERSTELLUNG_KFZ"
  },
  { label: "Hochbau, Bau von Gebäuden", value: "HOCHBAU" },
  { label: "Informationsdienstleistungen", value: "IT_SERVICE" },
  {
    label: "Instandhaltung/Reparatur/Handel mit Kraftfahrzeugen u. Service",
    value: "SERVICE_HANDEL_KFZ"
  },
  {
    label: "Kreative u. künstlerische u. unterhaltende Tätigkeiten",
    value: "KUNST_UNTERHALTUNG"
  },
  {
    label: "Lagerei sowie Erbringung von Dienstleistungen für Verkehr",
    value: "SERVICE_VERKEHR"
  },
  { label: "Landwirtschaft, Forstwirtschaft, Jagd", value: "LAND_FORST_JAGD" },
  { label: "Öffentliche Verwaltung", value: "OEFFENTLICH" },
  {
    label: "Sonstige freiberufliche/wissenschaftliche Tätigkeiten",
    value: "SONSTIGE_WISSENSCHAFT"
  },
  {
    label:
      "Wasserversorgung, Abwasser- und Abfallentsorgung u. Beseitigung von Umweltverschmutzungen",
    value: "WASSER_ABFALL_UMWELT"
  }
];

export const RULES = {
  gender: [{ required: true, message: "Bitte wählen Sie Ihre Anrede aus." }],

  firstName: [
    { required: true, message: "Bitte geben Sie Ihren Vornamen ein." },
    {
      pattern: /^[\u00C0-\u017Fa-zA-Z- ,.'-]+$/,
      message: "Dieses Feld darf keine Zahlen und Sonderzeichen enthalten."
    }
  ],

  lastName: [
    { required: true, message: "Bitte geben Sie Ihren Nachnamen ein." },
    {
      pattern: /[\u00C0-\u017Fa-zA-Z- ,.'-]+$/,
      message: "Dieses Feld darf keine Zahlen und Sonderzeichen enthalten."
    }
  ],

  street: [{ required: true, message: "Bitte geben Sie Ihre Straße ein." }],

  streetNr: [
    { required: true, message: "Bitte geben Sie Ihre Hausnummer ein." },
    {
      pattern: /^[1-9]\d*(?:[-\s]?[a-zA-Z0-9]+)?$/i,
      message:
        "Bitte geben Sie eine gültige Hausnummer ein (keine Sonderzeichen)."
    }
  ],

  zipCode: [
    { required: true, message: "Bitte geben Sie Ihre PLZ ein." },
    {
      pattern: /^\w{1,5}$/,
      message:
        "Bitte geben Sie eine gültige PLZ (zwischen 4 und 5 Ziffern) ein."
    }
  ],

  city: [
    { required: true, message: "Bitte geben Sie Ihren Ort ein." },
    {
      pattern: /^[\u00C0-\u017Fa-zA-Z\s-]+$/,
      message:
        "Bitte geben Sie einen gültigen Stadtnamen ein (keine Zahlen und Sonderzeichen)."
    }
  ],

  country: [
    { required: true, message: "Bitte geben Sie Ihr Land ein." },
    { validator: countryValidator }
  ],

  mobile: [
    {
      required: true,
      message:
        "Bitte geben Sie eine gültige Mobiltelefonnummer ein, diese muss mit einer Vorwahl (z.B. +49) beginnen und darf keine Leerzeichen enthalten. (z.B. +49171123456)"
    },
    {
      validator: phoneValidator,
      message: "Bitte geben Sie eine gültige Telefonnummer ein."
    }
  ],

  email: [
    {
      type: "email" as const,
      message: "Bitte geben Sie eine gültige E-Mail-Adresse ein."
    },
    { required: true, message: "Ihre E-Mail-Adresse" }
  ],
  emailJunior: [
    {
      type: "email" as const,
      message: "Bitte geben Sie eine gültige E-Mail-Adresse ein."
    }
  ],
  birthDate: {
    required: true,
    message: "Bitte geben Sie ein gültiges Geburtsdatum ein. (tt.mm.jjjj)"
  } as Rule,

  birthCity: [
    { required: true, message: "Bitte geben Sie Ihren Geburtsort ein." },
    {
      pattern: /^[\u00C0-\u017Fa-zA-Z\s-]+$/,
      message:
        "Bitte geben Sie einen gültigen Stadtnamen ein (keine Zahlen und Sonderzeichen)."
    }
  ],

  birthCountry: [
    { required: true, message: "Bitte geben Sie Ihr Geburtsland ein." }
  ],

  nationality: [
    { required: true, message: "Bitte geben Sie Ihre Staatsangehörigkeit ein." }
  ],

  maritalStatus: [
    { required: true, message: "Bitte wählen Sie Ihren Familienstand aus." }
  ],

  jobStatus: [
    { required: true, message: "Bitte geben Sie Ihren Berufsstatus ein." }
  ],

  jobBranch: [
    { required: true, message: "Wählen Sie Ihre Branche als Selbstständiger." }
  ],

  customJobStatus: [
    { required: true, message: "Bitte geben Sie Ihren Beruf ein." }
  ],

  additionalIncome: [
    { required: true, message: "Bitte geben Sie weitere Einkünfte ein." }
  ],

  politicallyExposed: [
    {
      required: true,
      message: "Bitte geben Sie an, ob Sie politisch exponiert sind."
    }
  ]
};

export const LABEL_INFO = {
  mobile: {
    title: "Telefon, mobil",
    text: `Wir haben einige Bereiche in Ihrem Dashboard mit der 2-Faktor-Authentisierung zusätzlich abgesichert, daher benötigen wir Ihre Mobilfunknummer (Telefon, mobil).
      Smavesto kann Sie darüber nur kontaktieren, sofern Sie uns im vorherigen Schritt die Einwilligung dazu erteilt haben.`,
    notValidUnderage:
      "Bitte geben Sie eine gültige Mobilfunknummer ein, diese muss mit einer Vorwahl (z.B. +49) beginnen und darf keine Leerzeichen enthalten. (z.B. +4917123456)",
    notValidHint:
      "Im Rahmen der 2-Faktor-Authentisierung ist es notwendig, dass Sie das Format Ihrer Mobilfunknummer anpassen. Bitte geben Sie eine gültige Mobilfunknummer ein, diese muss mit einer Vorwahl (z.B. +49) beginnen und darf keine Leerzeichen enthalten. (z.B. +4917123456)"
  },
  phone: {
    notValidHint:
      "Bitte geben Sie eine gültige Telefonnummer ein, diese muss mit einer Vorwahl (z.B. +49) beginnen und darf keine Leerzeichen enthalten. (z.B. +49421123456)"
  },
  politicallyExposed: {
    title: "Ich bin eine politisch exponierte Person",
    text: `Politisch exponierte Personen sind Menschen, die ein hochrangiges öffentliches Amt auf internationaler, europäischer oder nationaler Ebene ausüben oder ausgeübt haben.
      Ebenso umfasst sind Menschen, die ein öffentliches Amt unterhalb der nationalen Ebene innehaben oder -hatten, wenn die politische Bedeutung vergleichbar ist.
      Miterfasst sind Ehepartner, Eltern, Kinder und deren Ehepartner sowie nahestehende Personen. Für sie gelten strengere gesetzliche Regelungen im Hinblick auf Geldwäsche.`
  },
  politicallyExposedUnderage: {
    title: "Mein Kind ist eine politisch exponierte Person",
    text: "Politisch exponierte Personen sind Menschen, die ein hochrangiges öffentliches Amt auf internationaler, europäischer oder nationaler Ebene ausüben oder ausgeübt haben. Ebenso umfasst sind Menschen, die ein öffentliches Amt unterhalb der nationalen Ebene innehaben oder -hatten, wenn die politische Bedeutung vergleichbar ist. Miterfasst sind Ehepartner, Eltern, Kinder und deren Ehepartner sowie nahestehende Personen. Für sie gelten strengere gesetzliche Regelungen im Hinblick auf Geldwäsche."
  }
};
