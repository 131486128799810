import type { ReactNode } from "react";
import styled from "styled-components";

import EmptyState from "@components/placeholder/EmptyState";
import type { PropsWithChildren } from "@src/interfaces/jsx.interface";
import { SP } from "@styled-components/STags";
import { Col, Row } from "antd";
import React from "react";

type SectionWrapperProps = {
  title: string | React.ReactNode;
  editButton?: ReactNode;
  text?: React.ReactNode;
  sidebar?: React.ReactNode;
  isEmpty?: boolean;
} & PropsWithChildren;

export const SectionWrapper: React.FunctionComponent<SectionWrapperProps> = ({
  title,
  editButton,
  children,
  text,
  isEmpty,
  sidebar
}) => {
  const content = (
    <>
      <SHeader>
        <h2>{title}</h2>
        {!isEmpty && editButton}
      </SHeader>

      {text && <SP style={{ marginBottom: "0rem" }}>{text}</SP>}

      {isEmpty ? (
        <EmptyState text="Für Ihren Zugang sind keine Daten für diese Kategorie hinterlegt." />
      ) : (
        children
      )}
    </>
  );

  if (sidebar) {
    return (
      <Row justify="start">
        <Col xs={18} style={{ maxWidth: 750 }}>
          {content}
        </Col>
        <Col xs={6}>{sidebar}</Col>
      </Row>
    );
  }

  return content;
};

const SHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 2rem;
  align-items: center;

  h2 {
    color: inherit;
    font-size: 20px;
    margin-bottom: 0rem;
    font-weight: 600;
  }
`;
