import type { RuleObject } from "antd/lib/form";

import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

export const countryValidator = async (_: RuleObject, value: string) => {
  const country = await smavestoCore.services.common.getCountry(value);
  return new Promise((resolve, reject) => {
    if (isApiErrorResponse(country)) {
      reject(new Error("Beim prüfen des Landes ist ein Fehler aufgetreten."));
      return;
    }

    if (country.blacklisted)
      reject(
        new Error(
          "Das von Ihnen ausgewählte Land wird leider aus technischen Gründen nicht von uns unterstützt."
        )
      );

    resolve(undefined);
  });
};

export const phoneValidator = async (_: RuleObject, value: string) => {
  const result = await smavestoCore.services.validation.validate(
    "validate-phonenumber",
    `phonenumber=${
      value.indexOf("+") > -1
        ? value.replace("+", "%252B")
        : `+${value}`.replace("+", "%252B")
    }`
  );

  if (isApiErrorResponse(result)) throw new Error(result.humanReadableError);
};
